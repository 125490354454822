<template>
  <div id="web">
    <div class="contianer">
      <!-- 轮播 -->
      <div class="mian-slider">
        <Carousel v-model="picCarousel" dots="none" arrow="always" loop>
          <CarouselItem>
            <div class="carouselItem">
              <div class="itemFlex">
                <div class="content">
                  <h2 class="vertical-anim">全栈自研的一体化智慧教育生态</h2>
                  <!-- <div class="link-box">
                    <a href="#" class="theme-btn btn-style-one"><span class="txt">融合数仓</span></a>
                    <a href="#" class="theme-btn btn-style-two"><span class="txt">业务中台</span></a>
                    <a href="#" class="theme-btn btn-style-three"><span class="txt">应用中心</span></a>
                  </div> -->
                </div>
                <div class="image-box">
                  <img src="../../assets/main-slider/content-image.png" alt="">
                </div>
              </div>
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="bg2">
              <div class="carouselItem">
                <div class="itemFlex">
                  <div class="content">
                    <h2 class="vertical-anim">匠心精造，<br />用科技驱动创新</h2>
                  </div>
                  <div class="image-box">
                    <img src="../../assets/main-slider/content-image-1.png" alt="">
                  </div>
                </div>
              </div>
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="bg2">
              <div class="carouselItem">
                <div class="content">
                  <h2 class="vertical-anim">
                    AIGC校园先行者
                  </h2>
                </div>
                <div class="image-box">
                  <img style="width: 100%;" src="../../assets/main-slider/content-image-2.png" alt="">
                </div>
              </div>
            </div>
          </CarouselItem>
        </Carousel>
      </div>

      <!-- 第二块 -->
      <section class="services-section-two">
        <div class="auto-container">
          <div class="sec-title">
            <div class="title-icon">
              <span class="icon"><img src="../../assets/icons/separater.png" alt="" /></span>
            </div>
            <h2>我们的核心优势应用</h2>
            <!-- <div class="text">Going forward new normal that has evolved from generation the runway heading streamlined cloud solution <br> user generated content in real-time will have multiple touchpoints for offshoring</div> -->
          </div>
          <Row :gutter="30">
            <Col :xl="12" :lg="12" :md="24" :sm="24" class="innerCol" v-for="(item, index) in seoList" :key="index">
            <div class="inner-box" @click="toDetail(item.name)">
              <div class="content">
                <div class="icon-box">
                  <span class="icon"><img :src="item.pic" alt="" /></span>
                </div>
                <h3>
                  {{ item.title }}
                </h3>
                <div class="text">{{ item.text }}</div>
              </div>
            </div>
            </Col>
          </Row>
        </div>
      </section>

      <!-- 第一块 -->
      <section class="services-section">
        <div class="auto-container">
          <div class="sec-title">
            <div class="title-icon">
              <span class="icon"><img src="../../assets/icons/separater.png" alt="" /></span>
            </div>
            <!-- <div class="title">boost your sales easily in no time</div> -->
            <h2>智慧校园入口</h2>
          </div>
          <Row>
            <Col :xl="6" :lg="12" :md="12" :sm="24" :xs="24" v-for="(item, index) in innerList" :key="index">
            <a :href="item.loginUrl" target="_bank">
              <div class="inner-box" :class="item.bg">
                <div class="icon-box">
                  <span class="icon">
                    <img :src="item.innerPic" alt="" /></span>
                </div>
                <div class="lower-content">
                  <h3>
                    <a :href="item.loginUrl" target="_bank">{{
                      item.title
                    }}</a>
                  </h3>
                  <!-- <div class="text">{{item.text}}</div> -->
                </div>
              </div>
            </a>
            </Col>
          </Row>
        </div>
      </section>

      <!-- 第三块 -->
      <!-- <section class="seo-section">
        <div class="content-column">
          <div class="inner-column">
            <div class="sec-title">
              <div class="title-icon">
                <span class="icon"><img
                    src="../../assets/icons/separater.png"
                    alt=""
                  /></span>
              </div>
              <div class="title">boost your sales easily in no time</div>
              <h2>Leading Digital SEO Experets For All Business Solutions</h2>
            </div>
            <div class="text">
              <p>Podcasting operational change management inside of workflows to establish framework. Taking seamless key performance indicators offline to maximise the long tail. Keeping your eye on the ball while performing a deep dive on the start-up mentality.</p>
              <p>Collaboratively administrate empowered markets via plug-and-play networks. Dynamical procrastinate B2C users after installed base benefits.</p>
            </div>
            <ul class="list-style-one">
              <li><span class="icon"><img
                    src="../../assets/icons/7.png"
                    alt=""
                  /></span>Track the progress real-time & quickly maximize time</li>
              <li><span class="icon"><img
                    src="../../assets/icons/8.png"
                    alt=""
                  /></span>Efficiently unleash cross-media information with technology</li>
              <li><span class="icon"><img
                    src="../../assets/icons/9.png"
                    alt=""
                  /></span>Analytics dolor port magna lobortis finibus curabitur magna</li>
            </ul>
            <a
              href="#"
              class="theme-btn btn-style-two"
            ><span class="txt">discover more</span></a>
          </div>
        </div>

        <div class="right">
          <div class="big-image">
            <img
              src="../../assets/resource/image-1.png"
              alt=""
            />
            <div class="small-image">
              <img
                src="../../assets/resource/seo.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div style="clear:both"></div>
      </section> -->

      <!-- 第四块 -->
      <!-- <section class="competitor-section">
        <div class="auto-container">
          <Row>
            <Col span="8">
            <div class="feature-block">
              <div class="inner-box">
                <div class="icon-box">
                  <div class="step">Step 1</div>
                  <span class="icon">
                    <img
                      src="../../assets/icons/10.png"
                      alt=""
                    />
                  </span>
                </div>
                <h3><a href="">Complete Study Of Your Project</a></h3>
                <div class="text">For real-time schemas dramatically maintain clicks-and solutions without functional solutions. Completely syner frameworks to provide a robust synopsis.</div>
                <ul class="list-style-two">
                  <li>
                    <Icon
                      type="md-checkmark"
                      class="icon"
                    />Customizable Process
                  </li>
                  <li>
                    <Icon
                      type="md-checkmark"
                      class="icon"
                    />Content Management Solutions
                  </li>
                  <li>
                    <Icon
                      type="md-checkmark"
                      class="icon"
                    />Dominating with SEO & SEM
                  </li>
                  <li>
                    <Icon
                      type="md-checkmark"
                      class="icon"
                    />Full Training and Learnable Material
                  </li>
                </ul>
              </div>
            </div>
            </Col>
            <Col span="8">
            <div class="video-boxed">
              <div
                class="inner-box"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <h2>How to get pass <br> Your Competitors!</h2>
                <a
                  href="https://www.youtube.com/watch?v=kxPCFljwJws"
                  class="lightbox-image play-btn wow zoomInStable"
                  data-wow-delay="500ms"
                  data-wow-duration="2000ms"
                >
                  <Icon
                    style="line-height:100px"
                    type="ios-play-outline"
                    size="50"
                  />
                  <i class="ripple"></i>
                </a>
                <h3>Watch How It Works</h3>
              </div>
            </div>
            </Col>
            <Col span="8">
            <div
              class="feature-block"
              style="padding-left:40px"
            >
              <div class="inner-box">
                <div class="icon-box">
                  <div class="step">Step 2</div>
                  <span class="icon">
                    <img
                      src="../../assets/icons/11.png"
                      alt=""
                    />
                  </span>
                </div>
                <h3><a href="">REX SEO is Working For Growth</a></h3>
                <div class="text">For real-time schemas dramatically maintain clicks-and solutions without functional solutions. Completely syner frameworks to provide a robust synopsis.</div>
                <ul class="list-style-two">
                  <li>
                    <Icon
                      type="md-checkmark"
                      class="icon"
                    />Customizable Process
                  </li>
                  <li>
                    <Icon
                      type="md-checkmark"
                      class="icon"
                    />Content Management Solutions
                  </li>
                  <li>
                    <Icon
                      type="md-checkmark"
                      class="icon"
                    />Dominating with SEO & SEM
                  </li>
                  <li>
                    <Icon
                      type="md-checkmark"
                      class="icon"
                    />Full Training and Learnable Material
                  </li>
                </ul>
              </div>
            </div>
            </Col>
          </Row>
        </div>
      </section> -->

      <!-- 第五块 -->
      <!-- <section class="rules-section">
        <div class="auto-container">
          <Row>
            <Col span="10">
            <div class="inner-column">
              <div class="image">
                <a href="#"><img
                    src="../../assets/resource/image-2.png"
                    alt=""
                  /></a>
              </div>
            </div>
            </Col>

            <Col span="14">
            <div class="inner-column">
              <div class="sec-title">
                <div class="title-icon">
                  <span class="icon"><img
                      src="../../assets/icons/separater.png"
                      alt=""
                    /></span>
                </div>
                <div class="title">boost your sales easily in no time</div>
                <h2>Ideas To Rule Search Engines Social Media Optimization</h2>
              </div>
              <div class="text">
                <p>Podcasting operational change management inside of workflows to establish framework. Taking seamless key performance indicators offline to maximise the long tail. Keeping your eye on the ball while performing a deep dive on the start-up mentality.</p>
                <p>Collaboratively administrate empowered markets via plug-and-play networks. Dynamical procrastinate B2C users after installed base benefits.</p>
              </div>
              <a
                href="#"
                class="theme-btn"
              ><span class="txt">learn more</span></a>
            </div>
            </Col>
          </Row>
        </div>
      </section> -->

      <!-- 第六块 -->
      <!-- <section class="performing-section">
        <div class="auto-container">
          <div class="title-box">
            <h2>Want To Know How Your Website Is Performing?</h2>
            <div class="text">Synergize resource taxing relationships via premier niche markets. Professionally cultivate one-to-one customer service with robust ideas. Dynamically innovate resource-leveling customer SEO Needs</div>
          </div>
          <div class="report-form">
            <div class="form-inner">
              <Row :gutter="15">
                <Col span="12">
                <input
                  type="text"
                  name="text"
                  value=""
                  placeholder="Your website URL"
                  required
                >
                </Col>
                <Col span="6">
                <input
                  type="email"
                  name="email"
                  value=""
                  placeholder="Email"
                  required
                >
                </Col>
                <Col span="6">
                <button
                  type="submit"
                  class="orange"
                ><span class="txt">Get Free Report</span></button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section> -->

      <!-- 第七块 -->
      <!-- <section class="featured-section">
        <div class="auto-container">
          <div class="sec-title">
            <div class="title-icon">
              <span class="icon"><img
                  src="../../assets/icons/separater.png"
                  alt=""
                /></span>
            </div>
            <h2>Features That Deserve Top Rankings</h2>
            <div class="text">Objectively innovate new methods has evolved from generation the runway heading streamlined cloud solutions that <br> user generated content in real-time will have multiple touchpoints for offshoring.</div>
          </div>
          <Row>
            <Col
              span="8"
              v-for="(item,index) in feature"
              :key="index"
            >
            <div class="feature-block-two">
              <div class="inner-box ">
                <div class="icon-box">
                  <span class="icon"><img
                      :src="item.pic"
                      alt=""
                    /></span>
                </div>
                <h3><a href="#">{{item.title}}</a></h3>
                <div class="text">{{item.text}}</div>
              </div>
            </div>
            </Col>
          </Row>
        </div>
      </section> -->

      <!-- 第八块 -->
      <!-- <section class="fact-counter-section">
        <div class="auto-container">
          <Row>
            <Col
              span="6"
              class="faceList"
              v-for="(item,index) in factList"
              :key="index"
            >
            <div class="column">
              <div class="inner">
                <div class="count-outer count-box">
                  <span
                    class="count-text"
                    :class="item.textColor"
                  >{{item.number}}</span>
                  <h4 class="counter-title">{{item.title}}</h4>
                </div>
              </div>
            </div>
            </Col>
          </Row>
        </div>
      </section> -->

      <!-- 第九块 -->
      <!-- <section class="visitors-section">
        <div class="auto-container">
          <div class="sec-title light">
            <div class="title-icon">
              <span class="icon"><img
                  src="../../assets/icons/separater.png"
                  alt=""
                /></span>
            </div>
            <div class="title">Digital Marketing & SEo Tips Straight To Your Inbox</div>
            <h2>Convert Your Visitors Conversions Into Real <br> Insights To Gain Popularity & Visibility</h2>
          </div>
          <div class="emailed-form">
            <input
              type="text"
              name="email"
              value=""
              placeholder="Email Address..."
              required
            >
            <button
              type="submit"
              class="orange"
            ><span class="txt">Sign Me Up!</span></button>
          </div>
          <div class="image">
            <a href="#"><img
                src="../../assets/background/3.png"
                alt=""
              /></a>
          </div>
        </div>
      </section> -->

      <!-- 第十块 -->
      <!-- <section class="sponsors-section">
        <div class="auto-container">
          <div class="title-box">
            <h2>Trusted By Over 10 Million Happy Customers Worldwide</h2>
          </div>

          <div class="sponsors-carousel">
            <img
              src="../../assets/clients/1.jpg"
              alt=""
            >
            <img
              src="../../assets/clients/2.jpg"
              alt=""
            >
            <img
              src="../../assets/clients/3.jpg"
              alt=""
            >
            <img
              src="../../assets/clients/4.jpg"
              alt=""
            >
          </div>

        </div>
      </section> -->

      <!-- 第十一块 -->
      <!-- <section class="testimonial">
        <div class="image-column ">
          <div class="big-image"><img
              src="../../assets/background/4.png"
              alt=""
            ></div>
          <div class="inner-column">
            <div class="sec-title centered">
              <div class="title-icon">
                <span class="icon"><img
                    src="../../assets/icons/separater.png"
                    alt=""
                  /></span>
              </div>
              <h2>What Clients Saying</h2>
            </div>

            <div class="image image-one">
              <img
                src="../../assets/resource/author-1.jpg"
                alt=""
              />
            </div>

            <div class="image image-two">
              <img
                src="../../assets/resource/author-3.jpg"
                alt=""
              />
            </div>

            <div class="image image-three">
              <img
                src="../../assets/resource/author-2.jpg"
                alt=""
              />
            </div>

            <div class="image image-four">
              <img
                src="../../assets/resource/author-4.jpg"
                alt=""
              />
            </div>

            <div class="image image-five">
              <img
                src="../../assets/resource/author-5.jpg"
                alt=""
              />
            </div>

            <div class="image image-six">
              <img
                src="../../assets/resource/author-6.jpg"
                alt=""
              />
            </div>

            <div class="image image-seven">
              <img
                src="../../assets/resource/author-7.jpg"
                alt=""
              />
            </div>

          </div>
        </div>
        <div class="carousel-column">
          <div class="inner-column">
            <div
              class="inner-box"
              v-for="(item,index) in testList"
              :key="index"
            >
              <div class="text">{{item.text}}</div>
              <div class="author">{{item.name}} <span>{{item.from}}</span></div>
            </div>
          </div>
        </div>
        <div style="clear:both"></div>
      </section> -->

      <!-- 第十二块 -->
      <!-- <section class="news">
        <div class="auto-container">
          <div class="sec-title">
            <div class="title-icon">
              <span class="icon"><img
                  src="../../assets/icons/separater.png"
                  alt=""
                /></span>
            </div>
            <h2>Top Ranking News</h2>
            <div class="text">Objectively innovate new methods has evolved from generation the runway heading solution <br> user generated content in real-time will have multiple research results</div>
          </div>
          <Row :gutter="30">
            <Col
              span="8"
              v-for="(item,index) in newList"
              :key="index"
            >
            <div class="inner-box">
              <div class="image">
                <a href="#"><img
                    :src="item.pic"
                    alt=""
                  /></a>
              </div>
              <div class="lower-content">
                <ul class="post-meta">
                  <li>{{item.time}}</li>
                  <li>{{item.info}}</li>
                </ul>
                <h3><a href="#">{{item.title}}</a></h3>
                <div class="text">{{item.text}}</div>
                <a
                  href="#"
                  class="read-more"
                >Read More
                  <Icon
                    class="arrow"
                    type="ios-arrow-round-forward"
                  />
                </a>
              </div>
            </div>
            </Col>
          </Row>
        </div>
      </section> -->
    </div>
  </div>
</template>
<script>
import web from "./web.js";
export default web;
</script>

<style lang="less" scoped>
@import "./web.less";
</style>
