export default {
  data() {
    return {
      picCarousel: 0,
      // 第一块
      innerList: [{
        innerPic: require('../../assets/icons/icon-one.png'),
        title: '小学智慧校园入口',
        loginUrl: 'https://p.rayscloud.cn/',
        // text: 'Bring the table win survival strategies to ensure proactive domination.',
        bg: 'bg1'
      }, {
        innerPic: require('../../assets/icons/icon-two.png'),
        title: '中学智慧校园入口',
        loginUrl: 'https://m.rayscloud.cn/',
        // text: 'Bring the table win survival strategies to ensure proactive domination.',
        bg: 'bg2'
      }, {
        innerPic: require('../../assets/icons/icon-five.png'),
        title: '中高职智慧校园入口',
        loginUrl: 'https://zhi.rayscloud.cn/',
        // text: 'Bring the table win survival strategies to ensure proactive domination.',
        bg: 'bg3'
      }, {
        innerPic: require('../../assets/icons/icon-three.png'),
        title: '校智联入口',
        loginUrl: 'https://link.rayscloud.cn/',
        // text: 'Bring the table win survival strategies to ensure proactive domination.',
        bg: 'bg4'
      }],

      // 第二块
      seoList: [{
        pic: require('../../assets/icons/1.png'),
        title: '低代码搭建平台',
        name: 'xbzd',
        text: '低代码、可视化的校园应用搭建平台，可快速开发校园教育教学应用。'
      },
      {
        pic: require('../../assets/icons/2.png'),
        title: '学生综合评价（成长画像）平台',
        name: 'sshx',
        text: '平台利用大数据和人工智能等技术，采集并分析学生成长数据，生成全方位的学生成长画像。'
      },
      {
        pic: require('../../assets/icons/4.png'),
        title: '教师支持与发展系统',
        name: 'yxfz',
        text: '提供智能模型，依据学校特色打造专属模型，专注教师专业成长，涵盖教研、科研、培训等多场景。'
      },
      {
        pic: require('../../assets/icons/25.png'),
        title: '智慧物联融合平台',
        name: 'xzl',
        text: '基于智能感知能力的校园智慧融合平台，提供物联设备统一接入和开放式架构，管理各类前端感知设备。'
      },
      {
        pic: require('../../assets/icons/6.png'),
        title: '智慧考勤系统',
        name: 'zhkq',
        text: '系统实现了进出人员、师生的身份识别，并记录考勤数据，通过识别算法推送考勤信息给家长和管理人员。'
      },
      {
        pic: require('../../assets/icons/24.png'),
        title: '智慧屏幕系统',
        name: 'xbzp',
        text: '集成校园大数据、信息发布、班级管理、考勤测温、家校互通、物联管控等功能，是校园管理的得力助手。'
      },
      {
        pic: require('../../assets/icons/28.png'),
        title: '智慧生态系统',
        name: 'zhst',
        text: '平台运用大数据、物联网和AI技术,基于STEAM素质教育理念，通过智慧生态观测和传感设备，实现观察、记录。'
      },
      {
        pic: require('../../assets/icons/27.png'),
        title: '智慧阅读系统',
        name: 'zhyd',
        text: '平台依托数字技术，通过汇聚优质资源、营造互动场景、展示阅读成果，提供丰富多彩的读书空间。'
      },
      {
        pic: require('../../assets/icons/26.png'),
        title: '中高职学生实习与就业平台',
        name: 'zgzpt',
        text: '利用大数据和人工智能助力实习与就业平台，实现实习生高质量融合教育培养，毕业生更充分更高质量就业。'
      }
      ],

      // 第七块
      feature: [{
        pic: require('../../assets/icons/13.png'),
        title: 'Speed Optimization',
        text: 'Testing procedures for reliable supply chains engage top-line web services bring cutting-edge deliverables.'
      }, {
        pic: require('../../assets/icons/14.png'),
        title: 'Powerful Marketing',
        text: 'Testing procedures for reliable supply chains engage top-line web services bring cutting-edge deliverables.'
      }, {
        pic: require('../../assets/icons/15.png'),
        title: 'Advance Strategy',
        text: 'Testing procedures for reliable supply chains engage top-line web services bring cutting-edge deliverables.'
      }, {
        pic: require('../../assets/icons/16.png'),
        title: 'Get Experts Advice',
        text: 'Testing procedures for reliable supply chains engage top-line web services bring cutting-edge deliverables.'
      }, {
        pic: require('../../assets/icons/17.png'),
        title: 'Better Analytics',
        text: 'Testing procedures for reliable supply chains engage top-line web services bring cutting-edge deliverables.'
      }, {
        pic: require('../../assets/icons/18.png'),
        title: 'Achieve All Targets',
        text: 'Testing procedures for reliable supply chains engage top-line web services bring cutting-edge deliverables.'
      }],

      // 第八块
      factList: [{
        number: '120',
        title: 'Registered Clients',
        textColor: 'textColor1'
      },
      {
        number: '475',
        title: 'Registered Clients',
        textColor: 'textColor2'
      },
      {
        number: '839',
        title: 'Web Analytics Done',
        textColor: 'textColor3'
      },
      {
        number: '2.6K',
        title: 'Rankings Achieved',
        textColor: 'textColor4'
      }
      ],

      // 第十一块
      testList: [{
        text: 'Proactively envisioned services base expertise and cross-media all growth strategies. Seamlessly visualize quality intellectual capital without superior collaboration and idea-sharing for all installed base portals.',
        name: 'Thomas Benson',
        from: 'USA'
      }, {
        text: 'Proactively envisioned services base expertise and cross-media all growth strategies. Seamlessly visualize quality intellectual capital without superior collaboration and idea-sharing for all installed base portals.',
        name: 'Thomas Benson',
        from: 'USA'
      }],

      // 第十二块
      newList: [{
        pic: require('../../assets/resource/news-1.jpg'),
        time: 'Oct 25, 2019',
        info: 'SEO, Rankings',
        title: 'Credibly Innovate Growth Internal Organic Sources Rankings',
        text: 'Maintain extensive infomede extensible niches disseminate standardized metrics after resource leveling processes objectively ...'
      },
      {
        pic: require('../../assets/resource/news-2.jpg'),
        time: 'Nov 9, 2019',
        info: 'Optimizations',
        title: 'Optimal Alignments For Intuitive Bandwidth Applications',
        text: 'Maintain extensive infomede extensible niches disseminate standardized metrics after resource leveling processes objectively ...'
      },
      {
        pic: require('../../assets/resource/news-3.jpg'),
        time: 'Oct 25, 2019',
        info: 'Strategies',
        title: 'Frameworks To Provide With Robust Synopsis For Higher Growths',
        text: 'Maintain extensive infomede extensible niches disseminate standardized metrics after resource leveling processes objectively ...'
      }
      ],
    }
  },
  methods: {
    toDetail(name) {
      this.$router.push(name);
    },
  },
}